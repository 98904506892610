import { render, staticRenderFns } from "./category-custom.vue?vue&type=template&id=a30f8ec2"
import script from "./category-custom.vue?vue&type=script&lang=js"
export * from "./category-custom.vue?vue&type=script&lang=js"
import style0 from "./category-custom.vue?vue&type=style&index=0&id=a30f8ec2&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Progetti\\abbondanza\\ebsn-front-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a30f8ec2')) {
      api.createRecord('a30f8ec2', component.options)
    } else {
      api.reload('a30f8ec2', component.options)
    }
    module.hot.accept("./category-custom.vue?vue&type=template&id=a30f8ec2", function () {
      api.rerender('a30f8ec2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/category/category-custom.vue"
export default component.exports